import { Controller } from "@hotwired/stimulus"
import Swiper, { Autoplay} from 'swiper';
Swiper.use([Autoplay]);

export default class extends Controller {
  connect() {
   this.swiper = new Swiper(this.element, {
     slidesPerView: 1,
     loop: true,
     autoplay: {
       delay: 2500,
       disableOnInteraction: false,
     },
     breakpoints: {
       480: {
         slidesPerView: 2,
         spaceBetween: 30,
       },
       768: {
         slidesPerView: 4,
         spaceBetween: 30,
       },
     }
   })
  }

  disconnect() {
    this.swiper.destroy(true, true)
  }
}
